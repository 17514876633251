import firebase from 'firebase/app';
import 'firebase/firestore';
import store from '../store';
import router from '../router';

async function getUserDetails() {
  const user = await firebase.getCurrentUser();
  if (user) {
    const authToken = await user.getIdToken(true);
    store.commit('setAuthToken', authToken);
    store.dispatch('getMe');

    const userDoc = store.state.auth.me;
    if (userDoc) {
      let accountType = '';
      if (userDoc.type === 'super') {
        accountType = 'Super Admin';
        store.commit('setAdmin', true);
      } else if (userDoc.type === 'admin') {
        accountType = 'Admin';
        store.commit('setAdmin', true);
      } else {
        accountType = 'Standard';
        store.commit('setAdmin', false);
      }
      store.commit('setAccountType', accountType);
      store.commit('setUID', user.uid);
    } else {
      store.commit('setAdmin', false);
    }

    store.commit('setName', userDoc.name);
  }
}

function logout() {
  store.commit('logout');
  sessionStorage.clear();
  firebase
    .auth()
    .signOut()
    .then(() => {
      router.replace({
        name: 'login',
      });
    });
}

async function loggedIn() {
  const user = await firebase.getCurrentUser();
  if (user) {
    return true;
  }
  return false;
}

export {
  logout,
  getUserDetails,
  loggedIn,
}
