import store from '../../store';

function data() {
  const list = [{
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  }];

  // if (store.state.auth.accountType === 'Super Admin') {
  //   list.push({
  //     title: 'Events',
  //     icon: 'PenToolIcon',
  //     children: [{
  //       title: 'View',
  //       route: 'businesses',
  //     }, {
  //       title: 'Add',
  //       route: 'addEvent',
  //     }, {
  //       title: 'Users',
  //       icon: 'UsersIcon',
  //       route: 'users',
  //     }],
  //   });
  // }

  try {
    const perms = store.state.auth.me.permissions;

    if (perms.businesses.read) {
      const children = [{
        title: 'View',
        route: 'businesses',
      }];
      if (perms.businesses.create) {
        children.push({
          title: 'Add',
          route: 'businessAdd',
        })
      }

      list.push({
        title: 'Businesses',
        icon: 'TruckIcon',
        children,
      });
    }

    if (perms.users.read) {
      const children = [{
        title: 'View',
        route: 'users',
      }];
      if (perms.users.create) {
        children.push({
          title: 'Add',
          route: 'userAdd',
        })
      }

      list.push({
        title: 'Users',
        icon: 'UserIcon',
        children,
      });
    }

    if (perms.apiKey.read) {
      const children = [{
        title: 'View',
        route: 'apiKeys',
      }];
      if (perms.apiKey.create) {
        children.push({
          title: 'Add',
          route: 'apiKeyAdd',
        })
      }

      list.push({
        title: 'ApiKeys',
        icon: 'CodeIcon',
        children,
      });
    }

    if (perms.devices.read) {
      const children = [{
        title: 'View',
        route: 'devices',
      }];
      if (perms.devices.create) {
        children.push({
          title: 'Add',
          route: 'deviceAdd',
        })
      }

      list.push({
        title: 'Devices',
        icon: 'BoxIcon',
        children,
      });
    }
  } catch (err) {
    // TODO: Do something with the error here
  }
  return list;
}

export default data()
